<template>
  <div class="new-course pb-5">
    <v-card flat tile min-height="100" class="new-course-header gold d-flex align-center justify-center">
      <v-card tile flat max-width="400" width="100%" class="primary white--text h1 py-1 text-center">New course</v-card>
    </v-card>
    <div class="wrap mt-n5">
      <v-card flat class="pa-6 mb-4">
        <form>
          <div class="h4 mb-4">Course description</div>
          <div class="h11">Title<span class="error--text">*</span></div>
          <v-text-field
            class="field44"
            v-model="title"
            :error-messages="titleErrors"
            placeholder="Ex: English for beginner"
            outlined
            dense
            clearable
            clear-icon="mdi-close-circle"
            color="secondary"
            height="44"
          ></v-text-field>
          <div class="h11 mt-n3">Short description<span class="error--text">*</span></div>
          <v-text-field
            class="field44"
            v-model="short_desc"
            :error-messages="shortErrors"
            placeholder="Ex: Conversaton Practice"
            outlined
            dense
            clearable
            clear-icon="mdi-close-circle"
            color="secondary"
            height="44"
          ></v-text-field>
          <div class="d-flex mt-n3 align-center">
            <div class="h11">Description<span class="error--text">*</span></div>
            <v-spacer></v-spacer>
            <div class="text--text" style="font-size: 9px">Up to 600 symb.</div>
          </div>
          <v-textarea
            v-model="desc"
            :error-messages="descErrors"
            placeholder="Ex: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci amet"
            outlined
            dense
            counter
            clearable
            clear-icon="mdi-close-circle"
            color="secondary"
            auto-grow
            maxlength="600"
          ></v-textarea>
          <div class="h11 mt-n3">Category<span class="error--text">*</span></div>
          <v-select
            class="field44"
            v-model="category"
            :items="categoriesData"
            :error-messages="categoryErrors"
            placeholder="Select category"
            append-icon="mdi-chevron-down mdi-18px"
            outlined
            dense
            item-text="title"
            item-value="id"
            return-object
            color="secondary"
            height="44"
          ></v-select>
          <v-row>
            <v-col cols="12" sm="6" class="pb-sm-3 pb-0">
              <div class="h11 mt-n3">Level<span class="error--text">*</span></div>
              <v-select
                class="field44"
                v-model="level"
                :items="levelList"
                :error-messages="levelErrors"
                placeholder="Select level"
                append-icon="mdi-chevron-down mdi-18px"
                outlined
                dense
                color="secondary"
                height="44"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="pt-sm-3 pt-0">
              <div class="h11 mt-n3">Lesson duration, minutes<span class="error--text">*</span></div>
              <v-select
                class="field44"
                v-model="duration"
                :items="durationList"
                :error-messages="durationErrors"
                placeholder="Select duration"
                append-icon="mdi-chevron-down mdi-18px"
                outlined
                dense
                color="secondary"
                height="44"
              ></v-select>
            </v-col>
          </v-row>
          <div class="text-end">
            <v-btn @click="addCourse" color="primary" width="100" height="34">SAVE</v-btn>
          </div>
        </form>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      title: '',
      short_desc: '',
      desc: '',
      category: '',
      level: '',
      levelList: ['Beginner', 'Intermediate', 'Advanced', 'Proficient'],
      duration: '',
      durationList: [15, 30, 45, 60, 75, 90],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCategories');
  },
  methods: {
    notifi(btn) {
      if (btn == 'error') {
        this.$store.dispatch('setMsg', 'Sorry, but maximum number of courses reached (20 is max)');
        this.$store.dispatch('setColor', 'error');
      }
    },
    async addCourse() {
      this.error = [];
      const data = new Object();
      data.title = this.title;
      data.short_description = this.short_desc;
      data.description = this.desc;
      data.category = this.category.id;
      data.level = this.level.toLowerCase();
      data.lesson_duration_minutes = this.duration;
      await this.$store
        .dispatch('addCourses', data)
        .then((res) => {
          this.$router.push(`/course-edit/${res.data.id}`);
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'courses__max_count')) {
            this.notifi('error');
          }
        });
    },
  },
  computed: {
    categoriesData() {
      return this.$store.getters.categoriesList;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title course');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid (max 50 symbols)');
      return errors;
    },
    shortErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description__invalid') &&
        errors.push('Provided short description is not valid (max 150 symbols)');
      return errors;
    },
    descErrors() {
      const errors = [];
      this.error.find((item) => item == 'description__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      this.error.find((item) => item == 'category__required') && errors.push('Please enter category');
      this.error.find((item) => item == 'category__invalid') && errors.push('Provided category is not valid');
      return errors;
    },
    levelErrors() {
      const errors = [];
      this.error.find((item) => item == 'level__required') && errors.push('Please enter level');
      this.error.find((item) => item == 'level__required') && errors.push('Provided level is not valid');
      return errors;
    },
    durationErrors() {
      const errors = [];
      this.error.find((item) => item == 'lesson_duration_minutes__required') && errors.push('Please enter duration');
      this.error.find((item) => item == 'lesson_duration_minutes__invalid') && errors.push('Provided duration is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setCategories', []);
  },
};
</script>

<style lang="scss">
.new-course {
  &-header {
    background-image: url(../assets/img/header-img.svg);
    background-repeat: repeat !important;
  }
  .wrap {
    max-width: 568px;
  }
}
</style>
